/**
 * @copyright 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
 *
 * @author Christoph Wurst <christoph@winzerhof-wurst.at>
 * @author John Molakvoæ <skjnldsv@protonmail.com>
 *
 * @license GNU AGPL version 3 or any later version
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 *
 */
export var coreApps = ['', 'admin', 'log', 'core/search', 'core', '3rdparty'];
export var menuSpeed = 50;
export var PERMISSION_NONE = 0;
export var PERMISSION_CREATE = 4;
export var PERMISSION_READ = 1;
export var PERMISSION_UPDATE = 2;
export var PERMISSION_DELETE = 8;
export var PERMISSION_SHARE = 16;
export var PERMISSION_ALL = 31;
export var TAG_FAVORITE = '_$!<Favorite>!$_';